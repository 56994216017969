import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";
// get list of all partners
export const getAllPartners = async (
  page: number,
  pageSize: number,
  search: string,
) => {
  try {
    const res = await apiClient.get(
      `/partner/getAllPartner?currentPage=${page}&pageSize=${pageSize}&search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// get clients of specific partner
export const getPartnerClient = async (
  partnerId: any,
  page: number,
  pageSize: number,
  search: string,
) => {
  try {
    const res = await apiClient.get(
      `/partner/getPartnerClient?partnerId=${partnerId}&currentPage=${page}&pageSize=${pageSize}&search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// wuthdral request of all partners
export const partnerWithdrawalRequest = async (
  page: number,
  pageSize: number,
) => {
  try {
    const res = await apiClient.get(
      `/partner/partnerWithdrawalRequest?currentPage=${page}&pageSize=${pageSize}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const processWithdrawalRequest = async (data: any) => {
  try {
    const res = await apiClient.put("partner/processWithdrawalRequest", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
