import { ReactNode } from "react";
import "./FilterButton.scss";
interface IFilterButton {
  children: ReactNode;
  isActive?: boolean;
  handleFilter: (value: string) => void;
  name?: string | undefined;
}
const FilterButton: React.FC<IFilterButton> = ({
  children,
  isActive = false,
  handleFilter,
  name,
}) => {
  return (
    <>
      <div
        onClick={() => {
          handleFilter(name ?? "");
        }}
        className="parentFilterBtn"
      >
        <h6 className={`scoreBtn ${isActive ? "lightBtn" : ""}`}>{children}</h6>
      </div>
    </>
  );
};
export default FilterButton;
