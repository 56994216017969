import CustomModal from "../../components/CustomModal/CustomModal";
import "./CreateTicketPopup.scss";
import { useTranslation } from "react-i18next";
import CircleCrossIcon from "../../utils/Images/CircleCrossIcon";
import "./CreateTicket.scss";
import * as Yup from "yup";
import {
  AssessmentButton,
  InputField,
  SearchTypeSelect,
  Select,
  TextEditor,
} from "ui-components";
import { useEffect, useState } from "react";
import {
  createTicket,
  getTicketTypes,
} from "../../service/SupportTicket/supportTicket";
import { Field, Formik, Form } from "formik";

export interface IExperienceLeftSideProps {
  showCreateTicket?: boolean;
  label?: string;
  yesCallback?: any;
  noCallback?: any;
  isCrossBtn?: boolean;
  handleClose?: () => void;
  setShouldReferesh?: any
}

const CreateTicketPopup: React.FunctionComponent<IExperienceLeftSideProps> = ({
  showCreateTicket,
  // label,
  // yesCallback,
  // noCallback,
  isCrossBtn = false,
  handleClose = () => { },
  setShouldReferesh
}) => {
  const { t } = useTranslation();
  const [companies,] = useState([]);
  const [supportType, setSupportType] = useState([]);
  const defaultSelectCompanies = { value: "", label: "" };
  const [companyIdFromSelect, setCompanyIdFromSelect] = useState<any>(
    defaultSelectCompanies,
  );
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    fetchTicketTypes();
  }, []);
  const fetchTicketTypes = async () => {
    const companiesData = await getTicketTypes();
    const updateData = (companiesData?.data.data || []).map((item: any) => ({
      value: item?.supportTicketTypeId,
      label: item?.supportTicketTypeName,
    }));
    setSupportType(updateData);
  };
  const ticketData = {
    supportType: "",
    companyId: "",
    subject: "",
    description: "",
  };

  const ticketSchema = Yup.object().shape({
    supportType: Yup.string().required("Please select support type."),
    companyId: Yup.string(),
    subject: Yup.string().required("Subject is required."),
    description: Yup.string().required("Description is required."),
  });

  const handleCreateTicket = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    try {
      const data = {
        supportTicketTypeId: value?.supportType,
        userId: userId,
        userType: "ADMIN",
        status: "NEW",
        subject: value?.subject,
        companyId: "4a393cd0-9622-4165-af14-9601bf852d78",
        description: value?.description,
        assignId: "",
      };

      setSubmitting && setSubmitting(true);
      const response = await createTicket(data);
      if (response?.status === 200) {
        resetForm && resetForm();
        setCompanyIdFromSelect(defaultSelectCompanies)
        setShouldReferesh(true)
        handleClose();
      }
    } catch (error) {
      console.error("error99", error)
      setSubmitting && setSubmitting(false);

    } finally {
      setSubmitting && setSubmitting(false);

    }

  };

  return (
    <div>
      <CustomModal
        show={showCreateTicket}
        isHeaderVisible={false}
        isFooterVisible={false}
        className="CreateTicketPopup"
      >
        {isCrossBtn && (
          <div onClick={handleClose} className="popupCrossIcon">
            <CircleCrossIcon />
          </div>
        )}
        <div className="deleteData">
          <div className="noListFound">
            <div className="">
              {/* <DeleteAssessmentListIcon /> */}
              <div className="ticketWrapper ticket-modal-section">
                <div className="container-fluid mt-4 mb-4">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="newTicketForm">
                            <Formik
                              initialValues={ticketData}
                              validationSchema={ticketSchema}
                              onSubmit={(
                                value,
                                { resetForm, setSubmitting },
                              ) => {
                                handleCreateTicket(
                                  value,
                                  resetForm,
                                  setSubmitting,
                                );
                              }}
                            >
                              {({
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                isSubmitting,
                              }) => {
                                return (
                                  <Form>
                                    <div className="row">
                                      <div className="col-md-12 text-center mb-3">
                                        <h5 className="formHeading">
                                          CREATE A NEW TICKET
                                        </h5>
                                      </div>
                                      <div className="col-md-12 mt-3">
                                        <label className="formLabel" htmlFor="">
                                          Select support type
                                        </label>
                                        <Field name="supportType">
                                          {() => (
                                            <Select
                                              placeholder="Select support type"
                                              options={supportType}
                                              onChange={(newValue: any) => {
                                                setFieldValue(
                                                  "supportType",
                                                  newValue.target.value,
                                                );
                                              }}
                                              value={values.supportType}
                                            />
                                          )}
                                        </Field>
                                        {errors?.supportType &&
                                          touched?.supportType ? (
                                          <div className="errorMessage">
                                            {errors?.supportType}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="col-md-12 mt-3">
                                        <label className="formLabel" htmlFor="">
                                          Select company
                                        </label>
                                        <Field name="companyId">
                                          {() => (
                                            <SearchTypeSelect
                                              options={
                                                companies?.length ? companies : []
                                              }
                                              placeholder={`${t("Select company")} *`}
                                              value={companyIdFromSelect}
                                              onChange={(getValue: any) => {
                                                setCompanyIdFromSelect(getValue);
                                                setFieldValue("companyId", getValue?.value);
                                              }}
                                              loadingText={t("GENERAL.LOADING")}
                                              className="skillAssessList"
                                              liIconName="skillIcon"
                                            />
                                          )}
                                        </Field>
                                        {errors?.companyId &&
                                          touched?.companyId ? (
                                          <div className="errorMessage">
                                            {errors?.companyId}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-12 mt-3">
                                        <label className="formLabel" htmlFor="">
                                          Subject
                                        </label>
                                        <Field name="subject">
                                          {() => (
                                            <InputField
                                              placeholder={"Subject"}
                                              onChange={(newValue: any) =>
                                                setFieldValue(
                                                  "subject",
                                                  newValue,
                                                )
                                              }
                                              value={values.subject}
                                            />
                                          )}
                                        </Field>
                                        {errors?.subject && touched?.subject ? (
                                          <div className="errorMessage">
                                            {errors?.subject}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-12 mt-3">
                                        <label className="formLabel" htmlFor="">
                                          Description
                                        </label>
                                        <Field name="description">
                                          {() => (
                                            <TextEditor
                                              placeholder={""}
                                              onChange={(newValue: any) => {
                                                setFieldValue(
                                                  "description",
                                                  newValue,
                                                );
                                              }}
                                              value={values.description}
                                            />
                                          )}
                                        </Field>
                                        {errors?.description &&
                                          touched?.description ? (
                                          <div className="errorMessage">
                                            {errors?.description}
                                          </div>
                                        ) : null}
                                      </div>
                                      {/* <div className="col-md-12 mt-2">
                                        <label className="formLabel" htmlFor="">
                                          Files
                                        </label>
                                        <div className="attachTicketImg">
                                          <input
                                            id={"fileInput"}
                                            type="file"
                                            accept="image/*"
                                            // style={{ display: "none" }}
                                          />
                                          <span>
                                            Attachment <AttachmentIcon />
                                          </span>
                                        </div>
                                      </div> */}
                                      <div className="text-center">
                                        <AssessmentButton
                                          className="submitBtn"
                                          type="submit"
                                          disabled={isSubmitting}
                                        >
                                          {isSubmitting
                                            ? t("GENERAL.LOADING")
                                            : t("GENERAL.SUBMIT")}
                                        </AssessmentButton>
                                      </div>
                                    </div>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateTicketPopup;
