import React from "react";
import {
    AssessmentButton,
    AssessmentCheckboxRadio,
    SearchTypeSelect,
    TemplateSearch,
} from "ui-components";
import UpArrowIcon from "../../utils/Images/UpArrowIcon";
import DownArrowIcon from "../../utils/Images/DownArrowIcon";
import { CompanyListEnums } from "../../utils/Enums/CompanyListEnums";
import { useTranslation } from "react-i18next";
import { SupportTicketsEnums } from "../../utils/Enums/SupportTicketEnums";
import "../../pages/Company/CompanyList.scss"
type StatusOption = {
    value: string;
    checked: boolean;
    label: string;
};
type FilterSectionProps = {
    title: string;
    statusOptions: StatusOption[];
    queryParams: URLSearchParams;
    navigate: (path: string) => void;
    setInQueryParams: (
        params: URLSearchParams,
        key: string,
        value: string | number,
        navigate: (path: string) => void,
    ) => void;
    searchParam: string | undefined;
    handleKeyPressSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    clearAllFilter: () => void;
    isResetDisable: boolean;
    dropDownOptions?: { label: string; value: string }[];
    selectedCountry?: any;
    handleFilterClick: (filterKey: string) => void;
    pageName?: string;
    sortingFilters?: any
    selectedTicketStatus?: any
    setShowCreateTicket?: any
    searchPlaceholder?: any
};

const FilterSection: React.FC<FilterSectionProps> = ({
    title,
    statusOptions,
    queryParams,
    navigate,
    setInQueryParams,
    searchParam,
    handleKeyPressSearch,
    clearAllFilter,
    isResetDisable,
    dropDownOptions,
    selectedCountry,
    handleFilterClick,
    pageName,
    sortingFilters,
    selectedTicketStatus,
    setShowCreateTicket,
    searchPlaceholder = pageName === "tickets" ? "GENERAL.SEARCH_TICKET" : "GENERAL.SEARCH_COMPANY"
}) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="col-md-12">
                <div className="CreatedAssessmentListTitle">
                    <h2>{title}</h2>
                    {pageName === "tickets" && <AssessmentButton
                        onClick={() => {
                            setShowCreateTicket(true);
                        }}
                        className="whiteBtn"
                    >
                        Create New
                    </AssessmentButton>}
                </div>
            </div>
            <div className="filtersSec px-4">
                <div className="statusFilter">
                    <div className="w-75">
                        <div className="d-flex responseSecFilter">
                            {pageName === "companyList" &&
                                statusOptions.map((option) => {
                                    return (
                                        <div
                                            key={option.value}
                                            className="d-flex cursorPointer rtlGap"
                                            onClick={() => {
                                                setInQueryParams(
                                                    queryParams,
                                                    "st",
                                                    option.value,
                                                    navigate,
                                                );
                                                setInQueryParams(queryParams, "page", 1, navigate);
                                            }}
                                        >
                                            <AssessmentCheckboxRadio
                                                name="status"
                                                type="radio"
                                                checked={option.checked}
                                            />
                                            <p className="radioCheckLabel">{option.label}</p>
                                        </div>
                                    );
                                })}
                            {pageName === "tickets" &&
                                statusOptions.map((option) => {
                                    return (
                                        <div
                                            key={option.value}
                                            className="d-flex cursorPointer rtlGap"
                                            onClick={() => {
                                                setInQueryParams(
                                                    queryParams,
                                                    SupportTicketsEnums.FILTER_SUPPORT_TYPE,
                                                    option.value,
                                                    navigate,
                                                );
                                            }}
                                        >
                                            <AssessmentCheckboxRadio
                                                name="supportTypeParam"
                                                type="radio"
                                                checked={option.checked}
                                            />
                                            <p className="radioCheckLabel">{option.label}</p>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={"assessmentListSearchSec"}>
                        <TemplateSearch
                            onChange={(e) => {
                                setInQueryParams(queryParams, "search", e, navigate);
                                setInQueryParams(queryParams, "page", 1, navigate);
                            }}
                            placeholder={t(searchPlaceholder)}
                            handleKeyPress={handleKeyPressSearch}
                            iconName="searchList"
                            value={searchParam ?? ""}
                        />
                        <AssessmentButton
                            className="lightBtn mainLabelFilter filterBtn"
                            onClick={clearAllFilter}
                            disabled={isResetDisable}
                        >
                            {t("GENERAL.RESET")}
                        </AssessmentButton>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="filterClicked">
                        <div className="typeSelectSec w-100">
                            {dropDownOptions && <div className="typeSelectFilter">
                                <SearchTypeSelect
                                    options={dropDownOptions}
                                    placeholder={pageName === "tickets" ? t("GENERAL.STATUS") : t("GENERAL.SEARCH_COUNTRY")}
                                    value={pageName === "tickets" ? selectedTicketStatus : selectedCountry ?? ""}
                                    onChange={(getValue: any) => {
                                        setInQueryParams(
                                            queryParams,
                                            pageName === "tickets" ? "status" : "country",
                                            getValue.label,
                                            navigate,
                                        );
                                        setInQueryParams(queryParams, "page", 1, navigate);
                                    }}
                                />
                            </div>}

                            <div className="typeSelectDateSec">
                                {sortingFilters?.map((filter: any, idx: any) => {
                                    return (
                                        <label key={idx} htmlFor="" className="mainLabelFilter cursorPointer">
                                            <div className="arrowBtnSec">
                                                <p>{filter.label}</p>
                                                <div
                                                    onClick={() => handleFilterClick(filter.filterKey)}
                                                    className="parentFilterBtn">
                                                    <h6 className={`scoreBtn ${(queryParams.has(filter.filterKey) || queryParams.get("sortBy") === filter.filterKey) ? "borderGreen" : "borderGrey"}`} >
                                                        {filter.isActive !== CompanyListEnums.ASCENDING ? (
                                                            <UpArrowIcon
                                                                isDisable={queryParams.has(filter.filterKey) || queryParams.get("sortBy") === filter.filterKey}
                                                            />
                                                        ) : (
                                                            <DownArrowIcon
                                                                isDisable={queryParams.has(filter.filterKey) || queryParams.get("sortBy") === filter.filterKey}
                                                            />
                                                        )}
                                                    </h6>
                                                </div>
                                            </div>
                                        </label>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterSection;
