export const columns: any = [
  { Header: "Commenced By", accessor: "commencedBy" },
  { Header: "Date", accessor: "date" },
  { Header: "Recharge", accessor: "recharge" },
  { Header: "Tax & Discount", accessor: "tax&discount" },
  { Header: "Post Credit Balance", accessor: "postCreditBalance" },
];
export const detailHeading = [
  "Main Balance",
  "Reward Balance",
  "Tax",
  "Burned amount",
  "Payment Method",
  "Packages",
];
export const detailValue = [
  { mainBalance: "$4000" },
  { rewardBalance: "$245" },
  { tax: "10%" },
  { burnedAmount: "$200" },
  { paymentMethod: "UPI" },
  { packages: "Dummy" },
];

export const expanseColumns: any = [
  { Header: "Commenced By", accessor: "commencedBy" },
  { Header: "Date", accessor: "date" },
  { Header: "Debited", accessor: "debited" },
  { Header: "Post Credit Balance", accessor: "postCreditBalance" },
];
