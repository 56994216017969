import { useState } from "react";
import "./Table.scss";
import { useTranslation } from "react-i18next";
import { convertDate } from "../../utils/Common/Constant";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import { useSearchParams } from "react-router-dom";
import { AccountsEnums } from "../../utils/Enums/AccountsEnums";
export interface TableColumn<T> {
    Header: string;
    accessor: keyof T;
}

export interface TableProps<T> {
    columns: TableColumn<T>[];
    data: T[];
    detailHeading: any;
    detailValue: any;
}
const Table = <T extends Record<string, any>>({
    columns,
    data,
    detailHeading,
    detailValue,
}: TableProps<T>) => {
    const [searchParams] = useSearchParams();
    const [expandedRows,] = useState<number | null>(null);
    const activeTab = searchParams.get("at") || AccountsEnums.ACTIVE_TAB_EARNING
    // For future
    // const toggleExpandRow = (index: number) => {
    //     setExpandedRows(expandedRows === index ? null : index);
    // };
    const { t } = useTranslation();
    return (
        <div className="row mb-3 table-container ">
            <div className="tabel-header">
                {columns?.map((item, index) => (
                    <div key={index} className="col">
                        <span>{item?.Header}</span>
                    </div>
                ))}
            </div>
            {activeTab === AccountsEnums.ACTIVE_TAB_EARNING && <div className="container event-row">
                {data?.length > 0 ? (
                    data.map((item, index) => (
                        <div key={index}>
                            <div className="row mb-3 event-item">
                                <div className="col p-0">
                                    <span>{item.companyName ?? "-"}</span>
                                </div>
                                <div className="col ">
                                    <span>{item.date ? convertDate(item.date, false, true) : "-"}</span>
                                </div>
                                <div className="col">
                                    <span>{item.recharge ?? "-"}</span>
                                </div>
                                <div className="col">
                                    <span>{item.tax ?? "-"}</span>
                                </div>
                                <div className="col status-column">
                                    <span>{item.postBalance}</span>
                                </div>
                                {/* For future */}
                                {/* <div
                                    className={`${expandedRows === index ? "rotate-arrow" : "downArrow-icon"}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseRow${index}`}
                                    onClick={() => toggleExpandRow(index)}
                                >
                                    <DownArrow />
                                </div> */}
                            </div>

                            <div id={`collapseRow${index}`} className={`row mb-3 collapse ${expandedRows === index ? "show" : ""}`}>
                                <div className="col collapsable-details">
                                    <p className="details-text">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                        the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley
                                        of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                                    </p>
                                    <span className="details-text">{t("GENERAL.TOTAL_BALANCE")}:</span>
                                    <span className="details-total-balance">5000 $</span>

                                    <div className="detail-headers row mb-3">
                                        {detailHeading?.map((item: any, index: any) => (
                                            <div key={index} className="col">
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="detail-values row mb-3">
                                        {detailValue?.map((item: any, index: number) => (
                                            <div key={index} className="col">
                                                <span>{item?.mainBalance}</span>
                                                <span>{item?.rewardBalance}</span>
                                                <span>{item?.tax}</span>
                                                <span>{item?.burnedAmount}</span>
                                                <span>{item?.paymentMethod}</span>
                                                <span>{item?.packages}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <DataNotAvailable />
                )}

            </div>}
            {activeTab === AccountsEnums.ACTIVE_TAB_EXPANSES && <div className="container event-row">
                {data?.length > 0 ? (
                    data.map((item, index) => (
                        <div key={index}>
                            <div className="row mb-3 event-item">
                                <div className="col p-0">
                                    <span>{item.displayName ?? "-"}</span>
                                </div>
                                <div className="col ">
                                    <span>{item.date ? convertDate(item.date, false, true) : "-"}</span>
                                </div>
                                <div className="col">
                                    <span>{item.requestedCredits ?? "-"}</span>
                                </div>
                                <div className="col status-column">
                                    <span>{item.postBalance ?? "-"}</span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <DataNotAvailable />
                )}

            </div>}
        </div>
    );
};

export default Table;
