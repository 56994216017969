import {
  capitalizeFirstLetter,
  handleError,
} from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

export const getSupportTickets = async (
  currentPage: number,
  pageSize: number,
  search: string,
  createdAt: string,
  supportType: string,
  ticketStatus: string,
) => {
  try {
    const url =
      `/supportTicket/getAllSupportTicket?currentPage=${currentPage}&pageSize=${pageSize}` +
      (search ? `&search=${search}` : "") +
      (createdAt ? `&sortDir=${createdAt}` : "") +
      (supportType ? `&ticketType=${capitalizeFirstLetter(supportType)}` : "") +
      (ticketStatus
        ? `&ticketStatus=${ticketStatus.toUpperCase().replace(" ", "")}`
        : "");

    const res = await apiClient.get(url);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompanyById = async (companyId: any) => {
  try {
    const res = await apiClient.get(
      `/company/getCompany?companyId=${companyId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompaniesForTickets = async (search: any) => {
  try {
    const res = await apiClient.get(
      `/company/getAllCompanies?search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getTicketTypes = async () => {
  try {
    const res = await apiClient.get(
      "/supportTicketType/getAllSupportTicketType",
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const createTicket = async (data: any) => {
  try {
    const res = await apiClient.post(
      "/supportTicket/createSupportTicket",
      data,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getTicketById = async (ticketId: any) => {
  try {
    const res = await apiClient.get(
      `/supportTicket/getSupportTicket?supportTicketId=${ticketId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// get reported questions list
export const getReportedQuestionList = async (
  page: any,
  pageSize: any,
  search: string,
  sort: string,
) => {
  try {
    const res = await apiClient.get(
      `reportedQuestion/getAllReportedQuestion?currentPage=${page}&pageSize=${pageSize}&search=${search}&createdAt=${sort}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//get feedback list

export const getFeedbackList = async (
  page: any,
  pageSize: any,
  search: string,
  sort: string,
) => {
  try {
    const res = await apiClient.get(
      `feedback/getAllFeedback?currentPage=${page}&pageSize=${pageSize}&search=${search}&submittedDate=${sort}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

// ticket status update
export const updateTicketStatus = async (data: any) => {
  try {
    const res = await apiClient.put(
      "supportTicket/updateSupportTicketStatus",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
