import React, { useState, useEffect } from "react";
import "./DateRangePicker.scss";
import { useTranslation } from "react-i18next";

interface DateRangePickerProps {
    label: string;
    selectedDate: string;
    onDateChange: (date: string) => void;
    minDate: string;
    maxDate: string;
    invalidDate: boolean;
}

const DateRangePicker = ({
    label,
    selectedDate,
    onDateChange,
    minDate,
    maxDate,
    invalidDate,
}: DateRangePickerProps) => {
    const [formattedDate, setFormattedDate] = useState("");
    const { t } = useTranslation()
    useEffect(() => {
        if (selectedDate) {
            const date = new Date(selectedDate);
            const formatted = date.toISOString().split("T")[0];
            setFormattedDate(formatted);
        }
    }, [selectedDate]);

    const handleChange = (e: any) => {
        onDateChange(e.target.value);
        if (!e.target.value) {
            setFormattedDate(e.target.value);
        }

    };

    const handleFocus = (e: any) => {
        e.target.showPicker();
    };

    return (
        <div className="dateRangePicker">
            <label className="dateLabel">
                <span>{label}</span>
                <div>
                    <input
                        type="date"
                        value={formattedDate}
                        onChange={handleChange}
                        onClick={handleFocus}
                        min={minDate}
                        max={maxDate}
                    />
                    {invalidDate && <p className="invalidDateError">{t("ERROR.INVALID_DATE")}</p>}
                </div>
            </label>
        </div>
    );
};

export default DateRangePicker;
