import { handleError } from "../../utils/Common/Constant";
import { CompanyListEnums } from "../../utils/Enums/CompanyListEnums";
import apiClient from "../Connection/Connection";

export const getCompanies = async (
  currentPage?: number,
  pageSize?: number,
  search?: string,
  lastActive?: string,
  assessmentCount?: string,
  ticketCount?: string,
  onboardedDate?: string,
  isActive?: any,
  country?: any,
) => {
  const active =
    isActive === CompanyListEnums.STATUS_ACTIVE
      ? true
      : isActive === CompanyListEnums.STATUS_ALL
        ? ""
        : false;
  try {
    const url =
      `/company/getAllCompanies?currentPage=${currentPage}&pageSize=${pageSize}` +
      (search ? `&search=${search}` : "") +
      (lastActive ? `&lastActive=${lastActive}` : "") +
      (assessmentCount ? `&assessmentCount=${assessmentCount}` : "") +
      (ticketCount ? `&ticketCount=${ticketCount}` : "") +
      (onboardedDate ? `&onboardedDate=${onboardedDate}` : "") +
      (isActive ? `&isActive=${active}` : "") +
      (country ? `&location=${country}` : "");

    const res = await apiClient.get(url);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompanyById = async (companyId: any) => {
  try {
    const res = await apiClient.get(
      `/company/getCompany?companyId=${companyId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompaniesForTickets = async () => {
  try {
    const res = await apiClient.get("/company/getAllCompanies");
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const companyGetJobList = async (
  companyId: string,
  status: string,
  currentPage: number,
  pageSize: number,
  sortBy: string,
  sortDir: string,
  search: string,
) => {
  try {
    return (
      await apiClient.get(
        `company/jobList?companyId=${companyId}&currentPage=${currentPage}&pageSize=${pageSize}&status=${status}&sortBy=${sortBy}&sortDir=${sortDir}&search=${search}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};
