import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getReportedQuestionList } from "../../service/SupportTicket/supportTicket";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ReportedQuestionScreen.scss";
import {
    convertDate,
    handleKeyPressSearch,
    setInQueryParams,
    truncateString,
} from "../../utils/Common/Constant";
import { Pagination, TemplateSearch } from "ui-components";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import FilterButton from "../../components/FilterButton/FilterButton";
import DownArrowIcon from "../../utils/Images/DownArrowIcon";
import UpArrowIcon from "../../utils/Images/UpArrowIcon";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";

const ReportedQuestionScreen = () => {
    const { t } = useTranslation();
    const [reportedQuestionsList, setReportedQuestionsList] = useState<any[]>([]);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("search") || "";
    const [pageSize, setPageSize] = useState(10);
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const reportedQuestionRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Default to true, so skeleton shows initially
    const reportedQuestionPaginationRef = useRef<HTMLDivElement>(null);
    const params: any = new URLSearchParams(location.search);
    const timeoutRef = useRef<any>(null);
    const [filters, setFilters] = useState(() => {
        return {
            date: params.get("date") ? JSON.parse(params.get("date")) : false,
        };
    });
    useEffect(() => {
        if (reportedQuestionPaginationRef.current && managePaginationRef != null) {
            reportedQuestionPaginationRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [managePaginationRef]);
    const navigate = useNavigate();

    useEffect(() => {
        if (reportedQuestionRef.current && managePaginationRef != null) {
            reportedQuestionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);

    useEffect(() => {
        const debounceFunction = () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current as any);
            }
            timeoutRef.current = setTimeout(() => {
                getReportedQuestionListApi();
                window.scrollTo(0, 0);
            }, 500);
        };
        debounceFunction();
        return () => clearTimeout(timeoutRef.current);
    }, [pageSize, page, search, filters]);

    // Function to get all reported questions
    const getReportedQuestionListApi = async () => {
        try {
            const res = await getReportedQuestionList(
                page,
                pageSize,
                search,
                filters.date ? "asc" : "desc",
            );
            if (res?.status === 200) {
                setReportedQuestionsList(res?.data?.data?.data || []);
                setTotalCount(res?.data?.data?.totalCount);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Stop loading when API call finishes
        }
    };
    const handleFilter = (value: any) => {
        setFilters((prevFilters: any) => {
            // Handle "score" or "date" filter toggling separately
            const newFilters = {
                ...prevFilters,
                [value]: !prevFilters[value],
            };

            // Ensure "all" is set to false if any individual filter is selected
            if (newFilters.all) {
                newFilters.all = false;
            }

            // Update the URL with new filters
            const params = new URLSearchParams(window.location.search);
            Object.keys(newFilters).forEach((key) => {
                params.set(key, JSON.stringify(newFilters[key]));
            });

            // Update the URL without reloading the page
            window.history.replaceState(
                {},
                "",
                `${window.location.pathname}?${params.toString()}`,
            );

            return newFilters;
        });
    };

    return (
        <div className="container-fluid reportedQuestionsList">
            <div className="assessResponseHead mt-5">
                <div className="assessResponseHeadContent">
                    <h3>{t("GENERAL.REPORTED_QUESTIONS")}</h3>
                </div>
            </div>
            <div className="filtersSec" style={{ backgroundColor: "transparent" }}>
                <div className="col-md-12">
                    <div
                        className={"assessmentListSearchSec"}
                        ref={reportedQuestionPaginationRef}
                    >
                        <TemplateSearch
                            onChange={(e) => {
                                setInQueryParams(queryParams, "search", e, navigate);
                                setInQueryParams(queryParams, "page", 1, navigate);
                            }}
                            placeholder={t("GENERAL.SEARCH_REPORTED_QUESTION")}
                            handleKeyPress={handleKeyPressSearch}
                            iconName="searchList"
                            value={search ?? ""}
                        />
                    </div>
                </div>
                <div className="arrowBtnSec">
                    <p>{t("GENERAL.CREATED_DATE")}</p>
                    <FilterButton
                        handleFilter={handleFilter}
                        name="date"
                        isActive={filters?.date}
                    >
                        {filters?.date ? (
                            <DownArrowIcon isDisable={filters?.date} />
                        ) : (
                            <UpArrowIcon isDisable={filters?.date} />
                        )}
                    </FilterButton>
                </div>
            </div>
            {isLoading ? (
                <ShimmerLoader />
            ) : reportedQuestionsList?.length > 0 ? (
                <>
                    <div
                        className="responseReviewQuestion mt-3"
                        ref={reportedQuestionRef}
                    >
                        <div className="totalContentDiv">
                            {totalCount > 0 && (
                                <p className="totalContent">
                                    {t("GENERAL.TOTAL_REPORTED_QUESTIONS")} : {totalCount ?? "-"}
                                </p>
                            )}
                        </div>
                        {reportedQuestionsList?.map((item: any, index: number) => (
                            <div className={"assessResponseList"} key={index}>
                                <div className="assessQuestion">
                                    <div className="questionDetails">
                                        <div className="companyAssessdetail">
                                            <div className="companyAssessmentDiv">
                                                <div
                                                    className="d-flex flex-column right-content"
                                                    style={{ width: "60%" }}
                                                >
                                                    {item?.reportedBy === "Company" ?
                                                        item?.companyName && (
                                                            <>
                                                                <span className="firstSecTitle">
                                                                    {t("GENERAL.COMPANY")}
                                                                </span>
                                                                <span
                                                                    title={item?.companyName}
                                                                    className="firstSecValue"
                                                                >
                                                                    {item?.companyName}
                                                                </span>
                                                            </>
                                                        ) :
                                                        item?.applicantInfo && (
                                                            <>
                                                                <span className="firstSecTitle">
                                                                    {t("GENERAL.APPLICANT")}
                                                                </span>
                                                                <span
                                                                    title={item?.applicantInfo?.name}
                                                                    className="firstSecValue"
                                                                >
                                                                    {item?.applicantInfo?.name}
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                    {item?.assessmentName && (
                                                        <>
                                                            <span className="firstSecTitle">
                                                                {t("GENERAL.ASSESSMENT")}
                                                            </span>
                                                            <span
                                                                title={item?.assessmentName}
                                                                className="firstSecValue"
                                                            >
                                                                {item?.assessmentName}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-column align-items-end left-content">
                                                    <span className="reportedBy">
                                                        {t("GENERAL.REPORTED_BY")} : <b>{item?.reportedBy}</b>{" "}
                                                        <span className="seperationPipe">|</span>{" "}
                                                        {t("GENERAL.REPORTED_ON")} :{" "}
                                                        <b>{item?.reportedAt
                                                            ? convertDate(item?.reportedAt, false, true)
                                                            : "-"}</b>
                                                    </span>
                                                    <div className="typeStatus justify-content-between mt-2">
                                                        <div className="bulletSec">
                                                            {item?.skillName && (
                                                                <div
                                                                    className="questionSkillName"
                                                                    title={item?.skillName}
                                                                >
                                                                    {item?.isCustomQuestion
                                                                        ? t("GENERAL.CUSTOM_QUESTION")
                                                                        : `${item?.skillName ? truncateString(item?.skillName, 10) : "-"} - ${item?.level ? item?.level : "-"}`}
                                                                </div>
                                                            )}
                                                            <p className="d-flex">
                                                                {t("GENERAL.TYPE")} :
                                                                <span className="answerType">
                                                                    {item?.questionType}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="d-flex gap-2 questionText">
                                        <p>{index + 1}.</p>
                                        {item?.question ? (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: ` ${item?.question?.question}`,
                                                }}
                                            />
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                    <div className="reportedText-sec">
                                        <span className="reportedTextHeading">
                                            {t("GENERAL.REPORTED_TEXT")}
                                        </span>
                                        <br />
                                        <span className="reportedText">{item?.comment}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: any) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="my-5">
                    <DataNotAvailable />
                </div>
            )}
        </div>
    );
};

export default ReportedQuestionScreen;
