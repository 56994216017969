import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";
// get list of all partners
export const getAccountsTransaction = async (
  page: number,
  pageSize: number,
  search: string,
  startDate: string,
  endDate: string,
  companySelect: string,
) => {
  try {
    const res = await apiClient.get(
      `/account/companyTransactions?currentPage=${page}&pageSize=${pageSize}&search=${search}&startDate=${startDate}&endDate=${endDate}&company=${companySelect}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompanyList = async (search: any) => {
  try {
    const res = await apiClient.get(
      `/supportTicket/getAllCompany?search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getExpansesList = async (
  page: number,
  pageSize: number,
  search: string,
) => {
  try {
    const res = await apiClient.get(
      `partner/withdrawalList?currentPage=${page}&pageSize=${pageSize}&search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
