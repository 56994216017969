import { SkeletonList } from "ui-components"
const ShimmerLoader = () => {
    return (
        <div className="mt-5">
            {
                Array.from({ length: 5 }).map((_, index) => (
                    <SkeletonList
                        key={`skeleton-${index}`}
                        numberOfTitles={1}
                        numberofTextLines={5}
                        titleSize={18}
                        textSize={12}
                        thumbnailHeight={250}
                        thumbnailWidth={270}
                        itemsGap={20}
                    />
                ))
            }
        </div>
    )
}
export default ShimmerLoader