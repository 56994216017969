import { useEffect, useRef, useState } from "react";
import { getCompanies } from "../../service/Company/company";
import {
  AssessmentButton,
  Pagination,
  showToast,
} from "ui-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  capitalizeFirstLetter,
  convertDate,
  handleKeyPressSearch,
  setInQueryParams,
  truncateString,
} from "../../utils/Common/Constant";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import EmailIcon from "../../utils/Images/EmailIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import "./CompanyList.scss";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import LocationIcon from "../../utils/Images/LocationIcon";
import { CompanyListEnums } from "../../utils/Enums/CompanyListEnums";
import FilterSection from "../../components/FilterSection/FilterSection";
import JobIcon from "../../utils/Images/JobIcon";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";
const CompanyList = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const queryParams = new URLSearchParams(location.search);
  const initialFilterKey = [
    CompanyListEnums.FILTER_LAST_ACTIVE,
    CompanyListEnums.FILTER_ASSESSMENT_COUNT,
    CompanyListEnums.FILTER_TICKET_COUNT,
    CompanyListEnums.FILTER_ONBOARDED_DATE,
  ].find((key) => queryParams.has(key));
  const initialFilterValue = initialFilterKey
    ? queryParams.get(initialFilterKey) || ""
    : "";
  const [lastActive, setLastActive] = useState(
    initialFilterKey === CompanyListEnums.FILTER_LAST_ACTIVE &&
      initialFilterValue === CompanyListEnums.ASCENDING
      ? true
      : false,
  );
  const [assessmentCount, setAssessmentCount] = useState(
    initialFilterKey === CompanyListEnums.FILTER_ASSESSMENT_COUNT &&
      initialFilterValue === CompanyListEnums.ASCENDING
      ? true
      : false,
  );
  const [ticketCount, setTicketCount] = useState(
    initialFilterKey === CompanyListEnums.FILTER_TICKET_COUNT &&
      initialFilterValue === CompanyListEnums.ASCENDING
      ? true
      : false,
  );
  const [onboardedDate, setOnboardedDate] = useState(
    initialFilterKey === CompanyListEnums.FILTER_ONBOARDED_DATE &&
      initialFilterValue === CompanyListEnums.ASCENDING
      ? true
      : false,
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("st") || CompanyListEnums.STATUS_ACTIVE;
  const countrySelect = searchParams.get("country") || "";
  const [isResetDisable, setIsResetDisable] = useState(false);
  const searchParam = searchParams.get("search") || "";
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const timeoutRef = useRef<any>(null);
  const [companyList, setCompanyList] = useState<any>([]);
  const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
  const [listLoading, setListLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState({
    value: "",
    label: "",
  });
  useEffect(() => {
    setSelectedCountry({ value: countrySelect, label: countrySelect });
  }, [countrySelect]);
  const defaultContries = [
    { value: "USA", label: "USA" },
    { value: "India", label: "India" },
    { value: "Germany", label: "Germany" },
  ];
  const [activeFilter, setActiveFilter] = useState({
    key: initialFilterKey || CompanyListEnums.FILTER_LAST_ACTIVE,
    value: initialFilterValue || CompanyListEnums.ASCENDING,
  });

  useEffect(() => {
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any);
      }
      timeoutRef.current = setTimeout(getCompaniesList, 500);
    };
    debounceFunction();
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize, activeFilter, countrySelect]);
  useEffect(() => {
    if (createdAssessPaginationRef.current && managePaginationRef != null) {
      createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  // get company list api call
  const getCompaniesList = async () => {
    const search = searchParam?.trim() ?? "";
    const filterParams = {
      lastActive:
        activeFilter.key === CompanyListEnums.FILTER_LAST_ACTIVE
          ? activeFilter.value
          : "",
      assessmentCount:
        activeFilter.key === CompanyListEnums.FILTER_ASSESSMENT_COUNT
          ? activeFilter.value
          : "",
      ticketCount:
        activeFilter.key === CompanyListEnums.FILTER_TICKET_COUNT
          ? activeFilter.value
          : "",
      onboardedDate:
        activeFilter.key === CompanyListEnums.FILTER_ONBOARDED_DATE
          ? activeFilter.value
          : "",
    };
    try {
      const response = await getCompanies(
        search ? 1 : page,
        pageSize,
        search,
        filterParams.lastActive,
        filterParams.assessmentCount,
        filterParams.ticketCount,
        filterParams.onboardedDate,
        status,
        countrySelect
      );

      if (response?.status === 200) {
        setCompanyList(response?.data?.data);
        setTotalCount(response?.data?.totalCount);
      } else {
        showToast(response?.data?.customMessage, "error");
      }
    } catch (error) {
      showToast(
        "An error occurred while fetching the companies list.",
        "error",
      );
    } finally {
      setListLoading(false)
    }
  };

  // reset all filters
  const clearAllFilter = () => {
    setActiveFilter({
      key: CompanyListEnums.FILTER_LAST_ACTIVE,
      value: CompanyListEnums.ASCENDING,
    });
    const url = `/${currentLanguage}/company_list`;
    navigate(url, { replace: true });
  };
  useEffect(() => {
    if (activeFilter.key && activeFilter.value) {
      [
        CompanyListEnums.FILTER_LAST_ACTIVE,
        CompanyListEnums.FILTER_ASSESSMENT_COUNT,
        CompanyListEnums.FILTER_TICKET_COUNT,
        CompanyListEnums.FILTER_ONBOARDED_DATE,
      ].forEach((key) => {
        if (key !== activeFilter.key) {
          queryParams.delete(key);
        }
      });
      setInQueryParams(
        queryParams,
        activeFilter.key,
        activeFilter.value,
        navigate,
      );
    }
    const containsLastActiveAsc =
      queryParams.get(CompanyListEnums.FILTER_LAST_ACTIVE) ===
      CompanyListEnums.ASCENDING;
    const companyStatus = status === CompanyListEnums.STATUS_ACTIVE;
    const isSearchText = searchParam === "";
    const isCountrySelected = countrySelect === "";
    setIsResetDisable(
      containsLastActiveAsc &&
      companyStatus &&
      isSearchText &&
      isCountrySelected,
    );
  }, [activeFilter, searchParam, status, countrySelect]);

  // function to toggle he filter arrows
  const handleFilterToggle = (key: any) => {
    setActiveFilter((prevFilter) => {
      if (prevFilter.key === key) {
        return {
          key,
          value:
            prevFilter.value === CompanyListEnums.ASCENDING
              ? CompanyListEnums.DESCENDING
              : CompanyListEnums.ASCENDING,
        };
      }
      return { key, value: CompanyListEnums.DESCENDING };
    });
  };
  // function when filter click
  const handleFilterClick = (filterType: string) => {
    setLastActive(
      filterType === CompanyListEnums.FILTER_LAST_ACTIVE ? !lastActive : false,
    );
    setAssessmentCount(
      filterType === CompanyListEnums.FILTER_ASSESSMENT_COUNT
        ? !assessmentCount
        : false,
    );
    setTicketCount(
      filterType === CompanyListEnums.FILTER_TICKET_COUNT
        ? !ticketCount
        : false,
    );
    setOnboardedDate(
      filterType === CompanyListEnums.FILTER_ONBOARDED_DATE
        ? !onboardedDate
        : false,
    );
    handleFilterToggle(filterType);
    setInQueryParams(queryParams, "page", 1, navigate);
  };
  const sortingFilters = [
    {
      label: t("GENERAL.LASTLOGIN"),
      filterKey: CompanyListEnums.FILTER_LAST_ACTIVE,
      isActive: queryParams.get(CompanyListEnums.FILTER_LAST_ACTIVE),
    },
    {
      label: t("GENERAL.TOTAL_ASSESSMENT"),
      filterKey: CompanyListEnums.FILTER_ASSESSMENT_COUNT,
      isActive: queryParams.get(CompanyListEnums.FILTER_ASSESSMENT_COUNT),
    },
    {
      label: t("GENERAL.NO_OF_TICKETS"),
      filterKey: CompanyListEnums.FILTER_TICKET_COUNT,
      isActive: queryParams.get(CompanyListEnums.FILTER_TICKET_COUNT),
    },
    {
      label: t("GENERAL.ONBOARDED_COUNT"),
      filterKey: CompanyListEnums.FILTER_ONBOARDED_DATE,
      isActive: queryParams.get(CompanyListEnums.FILTER_ONBOARDED_DATE),
    },
  ]
  const statusOptions = [
    { value: CompanyListEnums.STATUS_ACTIVE, label: t("GENERAL.ACTIVE"), checked: status === CompanyListEnums.STATUS_ACTIVE },
    { value: CompanyListEnums.STATUS_INACTIVE, label: t("GENERAL.INACTIVE"), checked: status === CompanyListEnums.STATUS_INACTIVE },
  ];
  return (
    <>
      <div className="companyListWrapper CreatedAssessmentList">
        <div className="container-fluid mb-4">
          <div className="row">
            <FilterSection
              title={t("GENERAL.COMPANIES")}
              statusOptions={statusOptions}
              queryParams={queryParams}
              navigate={navigate}
              setInQueryParams={setInQueryParams}
              searchParam={searchParam}
              handleKeyPressSearch={handleKeyPressSearch}
              clearAllFilter={clearAllFilter}
              isResetDisable={isResetDisable}
              dropDownOptions={defaultContries}
              selectedCountry={selectedCountry}
              handleFilterClick={handleFilterClick}
              pageName="companyList"
              sortingFilters={sortingFilters}
            />
          </div>
          <div className="totalContentDiv" ref={createdAssessPaginationRef}>
            {totalCount > 0 && (
              <p className="totalContent">
                {t("GENERAL.TOTAL_COMPANIES")} : {totalCount ?? "-"}
              </p>
            )}
          </div>
          <div className="row">
            {listLoading ? <ShimmerLoader /> :
              companyList?.length ? (
                companyList?.map((company: any) => {
                  return (
                    <div className="AssessmentListContentSec" key={Math.random()}>
                      <div className="AssessmentListCard">
                        <div
                          className="AssessmentListCardImg cursorPointer"
                          onClick={() => {
                            navigate(
                              `/${currentLanguage}/companyProfile/${company?.companyId}`,
                            );
                          }}
                        >
                          {company?.logo ? (
                            <div className="profileImage">
                              <img src={company?.logo} className="logo-img" />
                            </div>
                          ) : (
                            <AssessmentListingIcon />
                          )}
                          <h4 className="mt-3">
                            {" "}
                            {company?.isActive
                              ? CompanyListEnums.STATUS_ACTIVE
                              : CompanyListEnums.STATUS_INACTIVE}
                          </h4>
                          <h6>
                            {convertDate(company?.createdAt, false, true) ?? "-"}
                          </h6>
                        </div>
                        <div className="AssessmentListCardDetails">
                          <div className="ListCardDetailsTitleSec">
                            <div className="ListCardDetailsTitle">
                              <div className="listItemSec">
                                <div className="ListCardTitleHeading">
                                  <div>
                                    <h3
                                      title={capitalizeFirstLetter(
                                        company?.companyName,
                                      )}
                                      className="cursorPointer"
                                      onClick={() => {
                                        navigate(
                                          `/${currentLanguage}/companyProfile/${company?.companyId}`,
                                        );
                                      }}
                                    >
                                      {capitalizeFirstLetter(
                                        truncateString(company?.companyName, 50),
                                      )}
                                    </h3>
                                    <p>{company?.industry?.name}</p>
                                    <div className="link_wrap">
                                      <p className="acc_style04">
                                        {company?.isReferred
                                          ? "Referred"
                                          : "Self"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="listIconSec">
                                    <>
                                      <div className="d-flex">
                                        <div
                                          className="cursorPointer"
                                          title={t("GENERAL.DELETE")}
                                          onClick={() => {
                                            navigate(
                                              `/${currentLanguage}/jobs/${company?.companyId}`,
                                            );
                                          }}
                                        >
                                          <JobIcon />
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                                <div className="email-mobile-section">
                                  {company?.email && (
                                    <a
                                      href={`mailto:${company?.email}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <EmailIcon /> {company?.email}
                                    </a>
                                  )}
                                  &nbsp; &nbsp;
                                  {company?.mobile && (
                                    <a href={`tel:${company?.mobile}`}>
                                      <PhoneIcon /> {company?.mobile}
                                    </a>
                                  )}
                                  &nbsp; &nbsp;
                                  {company?.country && (
                                    <a>
                                      <LocationIcon /> {company?.country}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ListContentSkillsSec">
                            <div className="ListContentSkills decreaseGap">
                              <div className="w-100">
                                <div>
                                  <p className="clickable-fields">
                                    {t("GENERAL.ACCOUNT_BALANCE")}
                                  </p>
                                  <h5>{company?.mainBalance ?? "-"}</h5>
                                </div>
                                <div className="w-100">
                                  <p className="clickable-fields">
                                    {t("GENERAL.TOTAL_SAVINGS")}
                                  </p>
                                  <h5>{company?.totalSaving ?? "-"}</h5>
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="w-100">
                                  <p>{t("GENERAL.ASSESSMENTS")}</p>
                                  <h5>{company?.assessmentCount ?? "-"}</h5>
                                </div>
                                <div className="w-100">
                                  <p>{t("GENERAL.LASTLOGIN")}</p>
                                  <h5 className="dateSize">
                                    {company?.lastLogin
                                      ? convertDate(
                                        company?.lastLogin,
                                        false,
                                        true,
                                      )
                                      : "-"}
                                  </h5>
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="w-100">
                                  <p
                                    className="clickable-fields"
                                    onClick={() =>
                                      navigate(
                                        `/${currentLanguage}/company_list/openTickets`,
                                      )
                                    }
                                  >
                                    {t("GENERAL.OPEN_TICKETS")}
                                  </p>
                                  <h5>{company?.openTicketCount ?? "-"}</h5>
                                </div>
                              </div>
                              <div className="w-100">
                                <div>
                                  <p className="clickable-fields">
                                    {t("GENERAL.LIFETIME_PURCHASE")}
                                  </p>
                                  <h5>{company?.totalPurchase ?? "-"}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="RightSideArrowSec">
                              <AssessmentButton
                                onClick={() => {
                                  navigate(
                                    `/${currentLanguage}/assessment_list/${company?.companyId}`,
                                    {
                                      state: {
                                        companyName: company?.companyName,
                                      },
                                    },
                                  );
                                }}
                                className="whiteBtn"
                              >
                                {t("GENERAL.VIEW")}
                              </AssessmentButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <DataNotAvailable />
              )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalCount || 0}
                pageSize={pageSize}
                onPageChange={(page: string) => {
                  setInQueryParams(queryParams, "page", page, navigate);
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyList;
