import { Pagination, SearchTypeSelect, SkeletonList, TemplateSearch } from "ui-components";
import Table from "../../../components/Table/Table";
import { columns, detailHeading, detailValue, expanseColumns } from "./AccountData";
import { useTranslation } from "react-i18next";
import "./Account.scss"
import { useEffect, useRef, useState } from "react";
import { getAccountsTransaction, getCompanyList, getExpansesList } from "../../../service/Accounts/AccountApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { currentDate, setInQueryParams, validateEndDate, validateStartDate } from "../../../utils/Common/Constant";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { AccountsEnums } from "../../../utils/Enums/AccountsEnums";
const Accounts = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search") || "");
  const companySelect = searchParams.get(AccountsEnums.FILTER_COMPANY) || "";
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(10);
  const queryParams = new URLSearchParams(location.search);
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const transactionPaginationRef = useRef<any>(null);
  const [startDate, setStartDate] = useState(searchParams.get(AccountsEnums.FILTER_START_DATE) || "");
  const [endDate, setEndDate] = useState(searchParams.get(AccountsEnums.FILTER_END_DATE) || "");
  const [invalidDate, setInvalidDate] = useState(false)
  const [searchCompany, setSearchCompany] = useState("")
  const [companies, setCompanies] = useState([])
  const defaultSelectCompanies = { value: "", label: "" };
  const [companyIdFromSelect, setCompanyIdFromSelect] = useState<any>(
    defaultSelectCompanies,
  );
  const [isLoading, setIsLoading] = useState(true)
  const activeTab = searchParams.get("at") || AccountsEnums.ACTIVE_TAB_EARNING
  useEffect(() => {
    setCompanyIdFromSelect({ value: companySelect, label: companySelect });
  }, [companySelect]);
  useEffect(() => {
    if (companyIdFromSelect?.label || companyIdFromSelect?.value) {
      setInQueryParams(queryParams, AccountsEnums.FILTER_COMPANY, companyIdFromSelect?.label, navigate);
    } else {
      setInQueryParams(queryParams, AccountsEnums.FILTER_COMPANY, "", navigate);
    }
  }, [searchCompany])
  useEffect(() => {
    const isStartDateValid = validateStartDate(startDate, endDate);
    const isEndDateValid = validateEndDate(endDate, startDate);
    if (!isStartDateValid || !isEndDateValid) {
      setInvalidDate(true);
    } else {
      setInvalidDate(false);
      setInQueryParams(queryParams, AccountsEnums.FILTER_START_DATE, startDate, navigate);
      setInQueryParams(queryParams, AccountsEnums.FILTER_END_DATE, endDate, navigate);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (transactionPaginationRef.current && managePaginationRef != null) {
      transactionPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  const navigate = useNavigate()
  useEffect(() => {
    if (activeTab === AccountsEnums.ACTIVE_TAB_EARNING) {
      const areDatesValid = (startDate && endDate) || (!startDate && !endDate);
      if (!invalidDate && areDatesValid) {
        getAccountsTransactionApi();
      }
    } else if (activeTab === AccountsEnums.ACTIVE_TAB_EXPANSES) {
      getExpansesListAPI()
    }
  }, [search, pageSize, page, startDate, endDate, companySelect, activeTab]);
  useEffect(() => {
    getCompanyListApi();
  }, [searchCompany])
  const getAccountsTransactionApi = async () => {
    try {
      const res = await getAccountsTransaction(page, pageSize, search || companySelect, startDate, endDate, companySelect)
      if (res?.status === 200) {
        setTotalCount(res?.data?.totalCount)
        setData(res?.data?.transactionList)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  }
  const getExpansesListAPI = async () => {
    try {
      const res = await getExpansesList(page, pageSize, search)
      if (res?.status === 200) {
        setTotalCount(res?.data?.totalCount)
        setData(res?.data?.processedWithrawalRequests)
        console.log(res?.data?.processedWithrawalRequests)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false)
    }
  }
  const getCompanyListApi = async () => {
    try {
      const res = await getCompanyList(searchCompany)
      const companyListData = await res?.data?.map((item: any) => ({
        value: item?.companyId,
        label: item?.companyName,
      }));
      setCompanies(companyListData)

    } catch (error) {
      console.error(error);

    }
  }

  return (
    <div className="account-container">
      <div className="header-row">
        <span>{t("GENERAL.TOTAL_BALANCE")}: $50,000</span>
      </div>
      <div className="search-bar">
        <TemplateSearch
          placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
          iconName="searchList"
          value={search}
          onChange={(e: string) => {
            setSearch(e)
            setInQueryParams(queryParams, "search", e, navigate);
          }}
        />
        <div className="accountsSidebar col-md-2">
          <div
            className={`sidebar-item ${activeTab === AccountsEnums.ACTIVE_TAB_EARNING ? "active" : ""}`}
            onClick={() => {
              setIsLoading(true)
              setInQueryParams(queryParams, "at", AccountsEnums.ACTIVE_TAB_EARNING, navigate);
            }}
          >
            {t("GENERAL.EARNING")}
          </div>
          <div
            className={`sidebar-item ${activeTab === AccountsEnums.ACTIVE_TAB_EXPANSES ? "active" : ""}`}
            onClick={() => {
              setIsLoading(true)
              setInQueryParams(queryParams, "at", AccountsEnums.ACTIVE_TAB_EXPANSES, navigate);
            }}
          >
            {t("GENERAL.EXPANSES")}
          </div>
        </div>
        {activeTab === AccountsEnums.ACTIVE_TAB_EARNING && <>
          <div style={{ display: "flex", alignItems: "end", gap: "5px" }}>
            <SearchTypeSelect
              options={
                companies?.length ? companies : []
              }
              placeholder={`${t("Select company")}`}
              value={companyIdFromSelect}
              onChange={(getValue: any) => {
                setCompanyIdFromSelect(getValue);
                setSearchCompany(getValue?.label)
              }}
              loadingText={t("GENERAL.LOADING")}
              className="skillAssessList"
              liIconName="skillIcon"
            />
            <div>
              <DateRangePicker
                label="Start Date"
                selectedDate={startDate}
                onDateChange={setStartDate}
                minDate={""}
                maxDate={currentDate}
                invalidDate={invalidDate}
              />
            </div>
            <div>
              <DateRangePicker
                label="End Date"
                selectedDate={endDate}
                onDateChange={setEndDate}
                minDate={""}
                maxDate={currentDate}
                invalidDate={invalidDate}
              />
            </div>
          </div>
          {isLoading ?
            <div className="mt-5">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonList
                  key={`skeleton-${index}`}
                  numberOfTitles={0}
                  numberofTextLines={1}
                  titleSize={0}
                  textSize={10}
                  thumbnailHeight={0}
                  thumbnailWidth={200}
                  itemsGap={20}

                />
              ))}
            </div>
            : <div className="accounts-table">
              <Table columns={columns} data={data} detailHeading={detailHeading} detailValue={detailValue} />
            </div>}
        </>}
        {activeTab === AccountsEnums.ACTIVE_TAB_EXPANSES && <>
          {isLoading ?
            <div className="mt-5">
              {Array.from({ length: 5 }).map((_, index) => (
                <SkeletonList
                  key={`skeleton-${index}`}
                  numberOfTitles={0}
                  numberofTextLines={1}
                  titleSize={0}
                  textSize={10}
                  thumbnailHeight={0}
                  thumbnailWidth={200}
                  itemsGap={20}
                />
              ))}
            </div>
            : <div className="accounts-table">
              <Table columns={expanseColumns} data={data} detailHeading={detailHeading} detailValue={detailValue} />
            </div>}
        </>}
      </div>

      <div className="row">
        <div className="col-md-12">
          <Pagination
            className="pagination-bar"
            currentPage={page ?? 1}
            totalCount={totalCount || 0}
            pageSize={pageSize}
            onPageChange={(page: any) => {
              setInQueryParams(queryParams, "page", page, navigate);
              setManagePaginationRef(Math.random());
            }}
            ofLabel={t("GENERAL.OF")}
            resultLabel={t("GENERAL.RESULTS")}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Accounts;
