import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFeedbackList } from "../../service/SupportTicket/supportTicket";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    capitalizeFirstLetter,
    convertDate,
    handleKeyPressSearch,
    setInQueryParams,
} from "../../utils/Common/Constant";
import { Pagination, TemplateSearch } from "ui-components";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import FilterButton from "../../components/FilterButton/FilterButton";
import DownArrowIcon from "../../utils/Images/DownArrowIcon";
import UpArrowIcon from "../../utils/Images/UpArrowIcon";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";

const FeedbackScreen = () => {
    const { t } = useTranslation();
    const [reportedQuestionsList, setReportedQuestionsList] = useState<any[]>([]);
    const [searchParams] = useSearchParams();
    const [pageSize, setPageSize] = useState(10);
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    const reportedQuestionRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true); // Default to true, so skeleton shows initially
    const feedbackPaginationRef = useRef<HTMLDivElement>(null);
    const search = searchParams.get("search") || "";
    const params: any = new URLSearchParams(location.search);
    const timeoutRef = useRef<any>(null);
    const [filters, setFilters] = useState(() => {
        return {
            date: params.get("date") ? JSON.parse(params.get("date")) : false,
        };
    });
    useEffect(() => {
        if (feedbackPaginationRef.current && managePaginationRef != null) {
            feedbackPaginationRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [managePaginationRef]);
    const navigate = useNavigate();

    useEffect(() => {
        if (reportedQuestionRef.current && managePaginationRef != null) {
            reportedQuestionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);

    useEffect(() => {
        const debounceFunction = () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current as any);
            }
            timeoutRef.current = setTimeout(() => {
                getFeedbackListApi();
                window.scrollTo(0, 0);
            }, 500);
        };
        debounceFunction();
        return () => clearTimeout(timeoutRef.current);

    }, [pageSize, page, search, filters]);

    // Function to get all reported questions
    const getFeedbackListApi = async () => {
        try {
            const res = await getFeedbackList(page, pageSize, search, filters.date ? "asc" : "desc",);
            if (res?.status === 200) {
                setReportedQuestionsList(res?.data?.data?.data || []);
                setTotalCount(res?.data?.data?.totalCount);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false); // Stop loading when API call finishes
        }
    };
    const handleFilter = (value: any) => {
        setFilters((prevFilters: any) => {
            // Handle "score" or "date" filter toggling separately
            const newFilters = {
                ...prevFilters,
                [value]: !prevFilters[value],
            };

            // Ensure "all" is set to false if any individual filter is selected
            if (newFilters.all) {
                newFilters.all = false;
            }

            // Update the URL with new filters
            const params = new URLSearchParams(window.location.search);
            Object.keys(newFilters).forEach((key) => {
                params.set(key, JSON.stringify(newFilters[key]));
            });

            // Update the URL without reloading the page
            window.history.replaceState(
                {},
                "",
                `${window.location.pathname}?${params.toString()}`,
            );

            return newFilters;
        });
    };

    return (
        <div className="container-fluid reportedQuestionsList">
            <div className="assessResponseHead mt-5">
                <div className="assessResponseHeadContent">
                    <h3>{t("GENERAL.FEEDBACK")}</h3>
                </div>
            </div>
            <div className="filtersSec" style={{ backgroundColor: "transparent" }}>
                <div className="col-md-12">
                    <div
                        className={"assessmentListSearchSec"}
                        ref={feedbackPaginationRef}
                    >
                        <TemplateSearch
                            onChange={(e) => {
                                setInQueryParams(queryParams, "search", e, navigate);
                                setInQueryParams(queryParams, "page", 1, navigate);
                            }}
                            placeholder={t("GENERAL.SEARCH_FEEDBACK")}
                            handleKeyPress={handleKeyPressSearch}
                            iconName="searchList"
                            value={search ?? ""}
                        />
                    </div>
                </div>
                <div className="arrowBtnSec">
                    <p>{t("GENERAL.SUBMITTED_DATE")}</p>
                    <FilterButton
                        handleFilter={handleFilter}
                        name="date"
                        isActive={filters?.date}
                    >
                        {filters?.date ? (
                            <DownArrowIcon isDisable={filters?.date} />
                        ) : (
                            <UpArrowIcon isDisable={filters?.date} />
                        )}
                    </FilterButton>
                </div>
            </div>
            {isLoading ? (
                <ShimmerLoader />
            ) : reportedQuestionsList?.length > 0 ? (
                <>
                    <div
                        className="responseReviewQuestion mt-3"
                        ref={reportedQuestionRef}
                    >
                        <div className="totalContentDiv">
                            {totalCount > 0 && (
                                <p className="totalContent">
                                    {t("GENERAL.TOTAL_FEEDBACKS")} : {totalCount ?? "-"}
                                </p>
                            )}
                        </div>
                        {reportedQuestionsList?.map((item: any, index: number) => (
                            <div className={"assessResponseList"} key={index}>
                                <div className="assessQuestion">
                                    <div className="questionDetails">
                                        <div className="companyAssessdetail">
                                            <div className="companyAssessmentDiv">
                                                <div
                                                    className="d-flex flex-column right-content"
                                                    style={{ width: "60%" }}
                                                >
                                                    <span className="firstSecTitle">
                                                        {t("GENERAL.APPLICANT")}
                                                    </span>
                                                    <span
                                                        title={item?.companyName}
                                                        className="firstSecValue"
                                                    >
                                                        {item?.applicantName
                                                            ? capitalizeFirstLetter(item?.applicantName)
                                                            : "-"}
                                                    </span>
                                                    <span className="firstSecTitle">
                                                        {t("GENERAL.ASSESSMENT")}
                                                    </span>
                                                    <span
                                                        title={item?.assessmentName}
                                                        className="firstSecValue"
                                                    >
                                                        {item?.assessmentName ?? "-"}
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column align-items-end left-content">
                                                    <div className=" ">
                                                        <span className="reportedBy ">
                                                            {t("GENERAL.COMPANY")} :{" "}
                                                        </span>
                                                        <span
                                                            title={item?.assessmentName}
                                                            className="reportedBy fw-bold"
                                                        >
                                                            {item?.companyName ?? "-"}
                                                        </span>
                                                    </div>
                                                    <span className="reportedBy">
                                                        {t("GENERAL.SUBMITTED_ON")} :{" "}
                                                        <b>
                                                            {item?.submittedDate
                                                                ? convertDate(item?.submittedDate, false, true)
                                                                : "-"}
                                                        </b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="reportedText-sec">
                                        <span className="reportedTextHeading">
                                            {t("GENERAL.APPLICANT_FEEDBACK")}
                                        </span>
                                        <br />
                                        <span className="reportedText">
                                            {item?.applicantFeedback ?? "-"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: any) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="my-5">
                    <DataNotAvailable />
                </div>
            )}
        </div>
    );
};

export default FeedbackScreen;
