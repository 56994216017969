import { useTranslation } from "react-i18next";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable"
import i18n from "../../i18nConfig";
import { useEffect, useRef, useState } from "react";
import { AssessmentButton, Pagination, showToast, TemplateSearch } from "ui-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { capitalizeFirstLetter, convertDate, handleKeyPressSearch, setInQueryParams, truncateString } from "../../utils/Common/Constant";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import EmailIcon from "../../utils/Images/EmailIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import { getAllPartners } from "../../service/Partner/PartnerApis";
import ShimmerLoader from "../../components/ShimmerLoader/ShimmerLoader";
const Partners = () => {
    const { t } = useTranslation();
    const currentLanguage = i18n.language;
    const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search") || "";
    const queryParams = new URLSearchParams(location.search);
    const [partnerList, setPartnerList] = useState<any>([])
    const navigate = useNavigate();
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const timeoutRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(true)
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    useEffect(() => {
        if (createdAssessPaginationRef.current && managePaginationRef != null) {
            createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);
    // get partner list api call
    useEffect(() => {
        const debounceFunction = () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current as any);
            }
            timeoutRef.current = setTimeout(getPartnerListApi, 500);
        };
        debounceFunction();
        return () => clearTimeout(timeoutRef.current);
    }, [location.search, searchParam, pageSize, page]);

    const getPartnerListApi = async () => {
        try {
            const response = await getAllPartners(
                page,
                pageSize,
                searchParam
            );

            if (response?.status === 200) {
                setPartnerList(response?.data?.data);
                setTotalCount(response?.data?.totalCount);
            } else {
                showToast(response?.data?.customMessage, "error");
            }
        } catch (error) {
            showToast(
                "An error occurred while fetching the companies list.",
                "error",
            );
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <>
            <div className="companyListWrapper CreatedAssessmentList">
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="CreatedAssessmentListTitle">
                                <h2>{t("GENERAL.PARTNERS")}</h2>
                            </div>
                        </div>
                        <div className="filtersSec" style={{ backgroundColor: "transparent" }}>
                            <div className="col-md-12">
                                <div
                                    className={"assessmentListSearchSec"}
                                    ref={createdAssessPaginationRef}
                                >
                                    <TemplateSearch
                                        onChange={(e) => {
                                            setInQueryParams(queryParams, "search", e, navigate)
                                            setInQueryParams(queryParams, "page", 1, navigate);

                                        }}
                                        placeholder={t("GENERAL.SEARCH_PARTNER")}
                                        handleKeyPress={handleKeyPressSearch}
                                        iconName="searchList"
                                        value={searchParam ?? ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="totalContentDiv">
                        {totalCount > 0 && (
                            <p className="totalContent">
                                {t("GENERAL.TOTAL_PARTNERS")} : {totalCount ?? "-"}
                            </p>
                        )}
                    </div>
                    <div className="row">
                        {isLoading ?
                            <ShimmerLoader /> :
                            partnerList?.length ? (
                                partnerList?.map((partner: any) => {
                                    return (
                                        <div className="AssessmentListContentSec" key={Math.random()}>
                                            <div className="AssessmentListCard">
                                                <div className="AssessmentListCardImg ">
                                                    {partner?.profilePic ? (
                                                        <div className="profileImage">
                                                            <img src={partner?.profilePic} className="logo-img" />
                                                        </div>
                                                    ) : (
                                                        <AssessmentListingIcon />
                                                    )}
                                                    <h4 className="mt-3"> {partner?.isActive ? "Active" : "Inactive"}</h4>
                                                    <h6>{convertDate(partner?.createdAt, false, true) ?? "-"}</h6>
                                                </div>
                                                <div className="AssessmentListCardDetails">
                                                    <div className="ListCardDetailsTitleSec">
                                                        <div className="ListCardDetailsTitle">
                                                            <div className="listItemSec">
                                                                <div className="ListCardTitleHeading">
                                                                    <div>
                                                                        <h3
                                                                            title={partner?.displayName && capitalizeFirstLetter(
                                                                                partner?.displayName,
                                                                            )}
                                                                        >
                                                                            {partner?.displayName ? capitalizeFirstLetter(
                                                                                truncateString(partner?.displayName, 50)
                                                                            ) : "-"}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                                <div className="email-mobile-section">
                                                                    {partner?.email && (
                                                                        <a
                                                                            href={`mailto:${partner?.email}`}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <EmailIcon /> {partner?.email}
                                                                        </a>
                                                                    )}
                                                                    &nbsp; &nbsp;
                                                                    {partner?.mobile && (
                                                                        <a href={`tel:${partner?.mobile}`}>
                                                                            <PhoneIcon /> {partner?.mobile}
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ListContentSkillsSec">
                                                        <div className="ListContentSkills decreaseGap">
                                                            <div className="w-100">
                                                                <div>
                                                                    <p>{t("GENERAL.REFERRED_COUNT")}</p>
                                                                    <h5>{partner?.referredCount ?? "-"}</h5>
                                                                </div>
                                                                <div >
                                                                    <p >
                                                                        {t("GENERAL.TOTAL_WITHDRAW")}
                                                                    </p>
                                                                    <h5>{partner?.totalWithdraw ?? "-"}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="w-100">
                                                                <div className="w-100">
                                                                    <p >
                                                                        {t("GENERAL.ONBOARDED_COUNT")}
                                                                    </p>
                                                                    <h5>{partner?.onboardedCount ?? "-"}</h5>
                                                                </div>
                                                                <div>

                                                                    <p>{t("GENERAL.LASTLOGIN")}</p>
                                                                    <h5 className="dateSize">{partner?.lastLogin ? convertDate(partner?.lastLogin, false, true) : "-"}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="w-100">
                                                                <div className="w-100">
                                                                    <p >
                                                                        {t("GENERAL.ACCOUNT_BALANCE")}
                                                                    </p>
                                                                    <h5>{partner?.currentAccountBalance ?? "-"}</h5>
                                                                </div>
                                                            </div>
                                                            <div className="w-100">
                                                                <div className="w-100">
                                                                    <p >
                                                                        {t("GENERAL.LIFETIME_EARNING")}
                                                                    </p>
                                                                    <h5>{partner?.lifeTimeEarning ?? "-"}</h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="RightSideArrowSec">
                                                            <AssessmentButton
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/${currentLanguage}/partner-client/${partner?.partnerId}`,
                                                                    );
                                                                }}
                                                                className="whiteBtn"
                                                            >
                                                                {t("GENERAL.VIEW")}
                                                            </AssessmentButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <DataNotAvailable />
                            )}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: string) => {
                                    setInQueryParams(queryParams, "page", page, navigate)
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partners